<template>
  <div class="barrage">
    <div class="barrage-content">
      <ul class="barrage-list" id="barrage-list">
        <li
          class="barrage-list-item"
          :id="barrage.id"
          :style="barrage.style"
          v-for="barrage in showBarrageList"
          :key="barrage.did"
          v-show="barrage.ready"
        >
          <img :src="barrage.avatar_small" alt="avatar" />
          <p>{{ barrage.content }}</p>
          <img
            v-if="barrage.like"
            class="like"
            src="https://static1.kaixinyf.cn/img/lza63b3e7050fef8601881322.png"
            alt="avatar"
          />
          <img v-else class="like" src="https://static1.kaixinyf.cn/img/lza63b3e7376f0a3238343183.png" alt="avatar" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Barrage',
    components: {},
    data() {
      return {
        time: 10,
        pageIndex: 1,
        finish: false,
        barrageContentWidth: '7.5rem',
        barrageList: [],
        showBarrageList: [],
        domIndex: 0,
      };
    },
    mounted() {
      this.getCommentList();
    },
    methods: {
      async getCommentList() {
        try {
          const comments = [
            {
              avatar_small:
                'https://static1.kaixinyf.cn/img/fa583cbc6933bca1928edcf400c2f33a.png?x-oss-process=image/resize,m_fixed,h_128,w_128',
              content: '一起来加油～',
            },
            {
              avatar_small:
                'https://thirdwx.qlogo.cn/mmopen/vi_32/b1WKhIzJttjfEYJXtNibgoYoqdu5tHO8FhAkppfPf4oicTWQdfOyarOiaiaGgAzUzFnL0gFLPJUvPMib4iaX0kT0oZiaQ/132',
              content: '超级好听！！！',
            },
            {
              avatar_small:
                'https://thirdwx.qlogo.cn/mmopen/vi_32/RQAN8GTNibNicbX9hjibW9sk9GFWGcOmJJsh6wicpqltxR7RZsGeicsL52AZS3HDQAz6YuEiasONQ4fo5fRULoBw5w6g/132',
              content: '大家国庆快乐～',
            },
            {
              avatar_small:
                'https://thirdwx.qlogo.cn/mmopen/vi_32/Q3auHgzwzM5H60eLcxyjR42Yg0ZrIkynWcyxZJ3uFK7ELAVVhTj3nHhudegNtRl9ltjDIPJogcUVuywibWHTpTg/132',
              content: '好听的歌就应该是这样的',
            },
            {
              avatar_small:
                'https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83epqOtKU28Nib7ojoUBiac2htsXibDpxCL3icjX0N3jmgcLHBrs5oo9Uaiat1sRFI3FIMrlG7PcKoD1Ojpg/132',
              content: '一起嗨！',
            },
            {
              avatar_small:
                'https://static1.kaixinyf.cn//img/16889b9acb108c9ce5c5938d33c4c68a.png?x-oss-process=image/resize,m_fixed,h_128,w_128',
              content: '啦啦啦啦啦，快来听歌吧',
            },
            {
              avatar_small:
                'https://thirdwx.qlogo.cn/mmopen/vi_32/qUy2w8bQrzRyewejgqXMkaD83jicZACYVygHaiaWVZ0RAjR5ibPCcufZqRmQjdGoaWIPAbibj3NiawBdTuYggxbye9Q/132',
              content: '国庆快乐哦，吼吼吼～',
            },
            {
              avatar_small:
                'https://thirdwx.qlogo.cn/mmopen/vi_32/LKhQibdeAeadtibk5ydWianFMI3Ww9vwhZonz5kzBnXQYe3IMHHpQYXhbiba4Bvg3qN5YoSlF6PwBvqc9AILMv2rvQ/132',
              content: '祝愿祖国繁荣昌盛、伟大富强！',
            },
            {
              avatar_small:
                'https://thirdwx.qlogo.cn/mmopen/vi_32/oR75qH1QTV9Iq0dsj84J2NYc0L7cqeCia8HZwOPibE1F8EWOnBzazpWich0Tmt26nqFeGQTY6CiaFArmSibpaEYibGQQ/132',
              content: '一起来为我们助力加油～',
            },
            {
              avatar_small:
                'https://thirdwx.qlogo.cn/mmopen/vi_32/MD1f4ic8ia56UhIjmMHAxtMLarKxYLJojCBfKUr7pgF78ajk4aJgpQaicBaKATeTrgBJDJSS7fYcGZIlTGCIeicgOA/132',
              content: '加油加油加油！！！',
            },
          ];
          this.formatBarrageList(comments);

          if (+is_end === 0) {
            this.finish = true;
          }
        } catch (e) {
          console.log(e);
        }
      },
      addComment(comment) {
        this.formatBarrageList([comment]);
      },
      getRandom(min, max) {
        return (
          Math.floor(Math.random() * (max - min + 1) + min) + Number((Math.abs(Math.random() - 0.8) * 1).toFixed(2))
        );
      },
      getDomTop(position = '') {
        let height = 1.8;

        switch (position) {
          case 'top':
            return this.getRandom(0, height / 3) + 'rem';
          case 'center':
            return this.getRandom(height / 3, (2 * height) / 3) + 'rem';
          case 'bottom':
            return this.getRandom((2 * height) / 3, height) + 'rem';
          default:
            return this.getRandom(0, height) + 'rem';
        }
        // return this.getRandom(0, height) + 'rem';
      },
      formatBarrageItem(item = {}) {
        let position;
        item.style = {};
        if (item.domIndex % 1 === 0) {
          position = 'top';
        } else if (item.domIndex % 2 === 0) {
          position = 'center';
        } else if (item.domIndex % 3 === 0) {
          position = 'bottom';
        }
        item.top = this.getDomTop(position);
        return item;
      },
      formatBarrageList(list) {
        console.log(`list`, list);
        let time = 0;
        list.forEach((item) => {
          item.id = `barrage-${this.domIndex}`;
          item.did = this.domIndex;
          item.like = false;
          this.domIndex++;
          item = this.formatBarrageItem(item);
          this.showBarrageList.push(item);
          time += 1;
          setTimeout(() => {
            this.$nextTick(() => {
              this.generateBarrage(item);
            });
          }, time * 1500);
        });
      },

      createBarrage(item = {}) {
        item.style = {
          top: item.top,
          right: 'calc(0%)',
          // animation: `moveRight ${this.time}s linear`,
          animationDuration: '10s',
          animationName: 'moveRight',
          animationTimingFunction: 'linear',
        };
        item.ready = true;
        this.$nextTick(function () {
          const dom = document.querySelector(`#${item.id}`);
          this.destroyBarrage(dom);
        });
      },
      destroyBarrage(dom) {
        const self = this;
        dom.addEventListener('animationend', function (e) {
          self.$nextTick(() => {
            self.showBarrageList = self.showBarrageList.filter((item) => {
              return item.id !== e.target.id;
            });

            if (
              (self.showBarrageList.length <= 6 && !self.finish) ||
              (self.showBarrageList.length === 0 && self.finish)
            ) {
              self.getCommentList();
            }
          });
        });
      },
      generateBarrage(item) {
        this.createBarrage(item);
      },
      handleClickBarrage(item) {
        item.like = true;
        this.trackClick('点击弹幕');
        this.handleDoTask('like_comment');
      },
    },
  };
</script>
<style>
  @keyframes moveRight {
    from {
      right: calc(-100%);
    }
    to {
      right: calc(7.5rem);
    }
  }
</style>

<style scoped lang="less">
  .barrage {
    &-list {
      position: relative;
      &-item {
        position: absolute;
        display: flex;
        align-items: center;
        padding-right: 0.12rem;
        height: 0.52rem;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 0.3rem;
        img {
          margin-left: 0.1rem;
          margin-right: 0.12rem;
          width: 0.32rem;
          height: 0.32rem;
          border-radius: 0.28rem;
          border: 1px solid rgba(221, 221, 221, 0.3);
        }
        img.like {
          border: none;
        }
        p {
          display: flex;
          white-space: pre;
          padding: 0 0.4rem;
          font-weight: 400;
          color: #ffffff;
          font-size: 0.24rem;
          letter-spacing: 1px;
        }
      }
    }
  }
</style>
