<template>
  <div class="province" :style="{ background: `url(${Province.province_info.background}) no-repeat center center` }">
    <a :href="buttonLink">
      <div class="province-mask"></div>
      <div class="province-content" @click.stop="handlePost">
        <div class="top-icon back_icon">
          <img src="https://static1.kaixinyf.cn/img/lza632832279c7fa325050945.png" alt="back" />
        </div>
        <div class="top-icon share_icon">
          <img src="https://static1.kaixinyf.cn/img/lza6328321e20c64852746502.png" alt="share" />
        </div>
        <div class="province-content-side">
          <div class="img-wrap">
            <img src="https://static1.kaixinyf.cn/img/lza6327388f98ec6321290048.png" alt="rule" />
          </div>
        </div>
        <div class="province-content-notice" v-if="notice">
          <van-notice-bar
            class="notice-bar"
            left-icon="https://static1.kaixinyf.cn/img/lza632841ad2e326551369476.png"
            color="#FA3123"
            background="rgba(250,49,35,0.2)"
            scrollable
          >
            当前{{ name }}助力值第一名
          </van-notice-bar>
        </div>
        <div class="province-content-name">欢迎来到{{ name }}</div>
        <!-- 弹幕区 -->
        <div class="province-content-barrage">
          <Barrage />
        </div>
        <!-- 弹幕区 -->
        <div class="province-content-button-area">
          <div class="button">为你的家乡献一首歌</div>
          <div class="province-content-button-area-round">
            <van-circle
              class="circle"
              v-model="currentRate"
              :stroke-width="60"
              :rate="rate"
              color="#FA3123"
              layer-color="none"
              size="0.72rem"
            />
            <div class="rotate-wrapper rotate">
              <img :src="currentMusic.cover" alt="" />
            </div>
          </div>
        </div>
        <div class="province-content-input-area">
          <div class="input">
            <img src="https://static1.kaixinyf.cn/img/lza632862e4e04f4396649156.png" alt="" />
            <input disabled type="text" placeholder="写下你的祝福 在这里发出来吧～" v-model="inputValue" />
          </div>
          <div class="send">发送</div>
          <div class="like">
            <img src="https://static1.kaixinyf.cn/img/lza632863422ff13777964361.png" alt="like" />
          </div>
        </div>
      </div>
      <audio
        ref="audioPlayer"
        :src="currentMusic.play_url"
        @canplaythrough="canPlayThrough"
        @timeupdate="timeUpdate"
      ></audio>
    </a>
    <IntroModal v-if="showIntro" @close="closeIntro" />
  </div>
</template>

<script>
  import Barrage from './components/Barrage.vue';
  import { mapGetters } from 'vuex';
  import IntroModal from '@/components/Modal/IntroModal/index.vue';
  import Download from '@/utils/download';

  export default {
    name: 'Province',
    components: { Barrage, IntroModal },
    data() {
      return {
        name: this.$route.query.name || '四川省',
        notice: '',
        shareInfo: {},
        provinceInfo: {},
        inputValue: '',
        currentRate: 0,
        ani: null,
        currentMusicIndex: 0,
        currentMusic: {},
        rate: 0,
        audioPlayer: null,
        isPlayMusic: false,
        progress: 0, // 播放进度
        currentTime: 0, // 当前播放时长
        duration: 0, // 播放总时长
        showIntro: false,
        buttonLink: 'javascript:void(0);',
      };
    },
    computed: {
      ...mapGetters('activity/national', ['Province']),
    },
    mounted() {
      this.notice = this.Province.notice;
      this.musicList = this.Province.music_list;
      this.setCurrentMusic();
      this.initAudioPlayer();
      if (this.$platform.Android && !this.$platform.WeiXin) {
        const SCHEME =
          this.$route.query.app_name && this.$route.query.app_name !== 'undefined'
            ? this.$route.query.app_name
            : 'kuaiyin';
        let web_url;

        if (process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'development') {
          web_url = `http://h5.rd.kaixinyf.cn/activity/national-day`;
        } else if (process.env.NODE_ENV === 'pub') {
          web_url = `https://h5.pub.kaixinyf.cn/activity/national-day`;
        } else if (process.env.NODE_ENV === 'production') {
          web_url = `https://h5.kaixinyf.cn/activity/national-day`;
        }

        this.buttonLink = `${SCHEME}://web?web_url=${web_url}`;
      } else {
        this.buttonLink = 'javascript:void(0);';
      }
    },
    methods: {
      setCurrentMusic() {
        this.currentMusic = this.musicList[this.currentMusicIndex];
      },
      /**
       * @description: 实例化音频
       * @param {cdn_url,ispause}
       * @return:viod
       */
      initAudioPlayer() {
        this.audioPlayer = this.$refs.audioPlayer;
        this.audioPlayer.addEventListener('ended', this.handleMusicPlayEnd);
      },
      handlePlayMusic() {
        console.log(`handlePlayMusic`);
        this.$nextTick(async function () {
          await this.playAudio();
        });
      },
      /**
       * @description: 播放音乐
       * @param {}
       * @return:viod
       */
      async playAudio() {
        try {
          await this.audioPlayer.play();
          return Promise.resolve(true);
        } catch (e) {
          console.log(`playAudio error`, e);
          return Promise.resolve(false);
        }
      },
      /**
       * @description: 暂停音乐
       * @param {}
       * @return:viod
       */
      async pauseAudio() {
        try {
          await this.audioPlayer.pause();
        } catch (e) {
          console.log(`pauseAudio error`, e);
        }
      },
      handleMusicPlayEnd() {
        this.currentMusicIndex++;
        this.setCurrentMusic();
        this.pauseAudio();
        this.$nextTick(function () {
          this.playAudio();
        });
      },
      canPlayThrough(e) {
        this.duration = e.target.duration;
      },
      timeUpdate(e) {
        this.currentTime = e.target.currentTime;
        this.progress = (this.currentTime / this.duration) * 100;
        this.currentRate = (this.currentTime / this.duration) * 100;
      },
      handlePost() {
        console.log(`handlePost`);
        this.handlePlayMusic();
        try {
          let jump = () => {
            let url;

            if (this.$platform.Android && this.$platform.WeiXin) {
              // url = Download.downLoad('yyb');
              this.showIntro = true;
              document.body.style.overflow = 'hidden';
              return;
            } else if (this.$platform.Android && !this.$platform.WeiXin) {
              url = Download.downLoad('yyb');
              setTimeout(() => {
                window.location.href = url;
              }, 3000);
              return;
            } else if (this.$platform.iOS) {
              url = Download.downLoad('yyb');
            } else {
              url = Download.downLoad('yyb');
            }
            window.location.href = url;
          };
          jump();
        } catch (e) {
          const { stack } = e;
          this.$logger.error({ error: e, message: stack, stack });
        }
      },
      closeIntro() {
        this.showIntro = false;
        document.body.style.overflow = 'auto';
      },
    },
  };
</script>
<style scoped lang="less">
  .province {
    position: relative;
    width: 7.5rem;
    height: 100vh;
    background-size: cover !important;
    &-mask {
      position: absolute;
      width: 7.5rem;
      height: 100vh;
      backdrop-filter: blur(18px);
      background: rgba(255, 255, 255, 0.2);
      z-index: 1;
    }
    &-content {
      position: absolute;
      width: 7.5rem;
      height: 100vh;
      z-index: 99;
      .top-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 1rem;
        width: 0.64rem;
        height: 0.64rem;
        background: rgba(135, 135, 135, 0.3);
        border-radius: 0.32rem;
        img {
          width: 0.26rem;
          height: 0.26rem;
        }
      }
      .share_icon {
        right: 0.3rem;
      }
      .back_icon {
        left: 0.3rem;
      }
      &-notice {
        margin: 1.76rem auto 0.36rem;
        width: 4.2rem;
        height: 0.46rem;
        border-radius: 0.24rem;
      }
      &-name {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        width: 4.16rem;
        height: 0.8rem;
        background: rgba(0, 0, 0, 0.16);
        border-radius: 0.46rem;
        font-size: 0.48rem;
        font-family: YouSheBiaoTiHei;
        color: #ffffff;
        line-height: 0.6rem;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
      }
      &-barrage {
        position: absolute;
        top: 4.44rem;
        left: 0;
        width: 7.5rem;
        height: 4.32rem;
      }
      &-side {
        position: fixed;
        top: 2.12rem;
        right: 0;
        width: 0.98rem;
        height: 0.86rem;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 1rem 0px 0px 1rem;
        z-index: 99;
        .img-wrap {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 0.06rem;
          margin-left: 0.06rem;
          width: 0.74rem;
          height: 0.74rem;
          background: #ffffff;
          border-radius: 50%;
          img {
            width: 0.58rem;
            height: 0.58rem;
          }
        }
      }
      &-button-area {
        display: flex;
        justify-content: space-between;
        width: 5.86rem;
        position: absolute;
        bottom: 3.02rem;
        left: 0.82rem;
        .button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 4.8rem;
          height: 0.96rem;
          background: #ffffff;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
          border-radius: 0.48rem;
          font-size: 0.36rem;
          font-weight: 600;
          color: #fa3123;
          border: none;
        }
        &-round {
          display: flex;
          position: relative;
          align-items: center;
          justify-content: center;
          width: 0.96rem;
          height: 0.96rem;
          background: #ffffff;
          box-shadow: 0px 0.06rem 0.4rem 0px rgba(3, 11, 26, 0.15);
          border-radius: 0.48rem;
          .rotate-wrapper {
            position: relative;
            width: 0.66rem;
            height: 0.66rem;
            display: flex;
            align-items: center;
            justify-content: center;
            background: url('https://static1.kaixinyf.cn/img/lza632af4fa4360f946175300.png');
            background-size: cover;
            border-radius: 50%;
            img {
              width: 0.36rem;
              height: 0.36rem;
              border-radius: 50%;
            }
          }
          .circle {
            position: absolute;
            top: 0.12rem;
            left: 0.12rem;
            z-index: 2;
          }
        }
      }
      &-input-area {
        position: absolute;
        bottom: 0.98rem;
        left: 0.3rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 6.9rem;
        .input {
          padding-left: 0.28rem;
          padding-right: 0.4rem;
          display: flex;
          align-items: center;
          width: 3.8rem;
          height: 0.8rem;
          background: rgba(0, 0, 0, 0.51);
          border-radius: 0.1rem;
          img {
            margin-right: 0.12rem;
            width: 0.24rem;
            height: 0.24rem;
          }
          input {
            width: 3.44rem;
            font-size: 0.24rem;
            color: #ffffff;
            background: none;
          }
        }
        .send {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 1.06rem;
          height: 0.8rem;
          background: rgba(0, 0, 0, 0.51);
          border-radius: 0.1rem;
          font-weight: 600;
          font-size: 0.28rem;
          color: #ffffff;
        }
        .like {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 1.06rem;
          height: 0.8rem;
          background: rgba(0, 0, 0, 0.51);
          border-radius: 0.1rem;
          img {
            width: 0.4rem;
            height: 0.4rem;
          }
        }
      }
    }
  }
  .notice-bar {
    height: 0.46rem !important;
    border-radius: 0.24rem;
  }
  .rotate {
    animation: rotate 30s linear infinite;
  }
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
